import React, { useEffect, useState } from 'react';
import { useGetPluginsByEnvQuery } from '../features/api';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

interface Plugin {
  id: number;
  firstName: string;
  lastName: string;
  handle: string;
}

const selectStyle: React.CSSProperties = {
  borderRadius: '8px',
  backgroundColor: 'black',
  color: '#888888',
  cursor: 'pointer',
  padding: '5px',
  textAlign: 'center',
};

const PluginList: React.FC = () => {
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const { data: queryData, isLoading } = useGetPluginsByEnvQuery({});
  const navigate = useNavigate();

  const navigateToPlugin = (plugin: any) => {
    navigate(`/store/${plugin}`);
  };
  const navigateToEnvironment = (projectId: any, environment: any) => {
    console.log('wwwwww');
    navigate(`/projects/${projectId}/?environment=${environment}`);
  };

  useEffect(() => {
    if (queryData) {
      console.log(queryData, 'queryData');
      setPlugins(queryData);
    }
  }, [queryData]);

  if (isLoading) {
    return <Loader />;
  }

  const groupedPlugins = plugins.reduce((acc: any, plugin: any) => {
    const pluginSlug = plugin.plugins.plugin_slug;
    if (!acc[pluginSlug]) {
      acc[pluginSlug] = {
        ...plugin.plugins,
        environments: [],
      };
    }
    acc[pluginSlug].environments.push(
      ...plugin.environment.map((env: any) => ({
        environment: env.environment.EnvId,
        project_id: env.projects.project_id,
      }))
    );
    return acc;
  }, {});

  console.log(groupedPlugins, 'ggggggggggggg');

  const groupedPluginList = Object.values(groupedPlugins);

  console.log('first', groupedPluginList);

  return (
    <main className='main'>
    <div className='container-fluid'>
      <div className='table-responsive'>
        <table className='table table-dark' style={{ marginTop: '70px' }}>
          <thead>
            <tr>
              <th scope='col' style={{ textAlign: 'center' }}>
                Plugin Names
              </th>
              <th scope='col' style={{ textAlign: 'center' }}>
                Env Id
              </th>
              <th scope='col' style={{ textAlign: 'center' }}>
                Version
              </th>
            </tr>
          </thead>
          <tbody>
            {groupedPluginList.map((plugin: any, index) => (
              <tr key={index}>
                <td
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => navigateToPlugin(plugin.plugin_slug)}
                >
                  {plugin.plugin_slug}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {plugin.environments.map((env: any, index: any) => (
                    <span
                      key={index}
                      style={{ cursor: 'pointer', marginRight: index < plugin.environments.length - 1 ? '5px' : '0' }}
                      onClick={() => navigateToEnvironment(env.project_id, env.environment)}
                    >
                      {env.environment},
                    </span>
                  ))}
                </td>{' '}
                <td style={{ textAlign: 'center' }}>
                  <select style={selectStyle}>
                    {plugin.plugin_version_id.map((version: any, vIdx: number) => (
                      <option key={vIdx} value={version.version}>
                        {version.version}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </main>
  );
};

export default PluginList;
