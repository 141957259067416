/* eslint-disable no-empty-pattern */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { IitemList, IProject, Iitem } from '../Interface/appInterface';

import favorite_unselect from '../img/favorite.svg';
import favorite_select from '../img/favorite_select.svg';
import img from '../img/store-thumbnail.svg';

import { Rating } from 'react-simple-star-rating';
import { useGetDataByPluginQuery, useUserSessionMutationMutation } from '../features/api';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
const StorePlugin:React.FC<IitemList> =({
  plugin,
  active,
  view,
  setSelectPluginData,
  selectPluginData,
  isSelectedActive,
}: IitemList) => {
  const [syncUserSession, {}] = useUserSessionMutationMutation();
  let existinProject: number[] = [];
  let projects = useSelector((state: any) => state.queue.value.all);
  projects = Object.values(projects);
  projects.map((project: IProject) => {
    if (project.plugins !== null) {
      if (project.plugins.some((el: Iitem) => el.id === plugin.id)) {
        existinProject.push(project.id);
      }
    }
  });
  const [wishlist, setWishlist] = useState([]);
  // const [isPluginInWishlist,setIsPluginInWishlist] = useState(false);
  const [color, setColor] = useState('');
  // const { slug } = useParams();
  // const { data: pluginDetails, error, isLoading, isError } = useGetDataByPluginQuery({ plugin_id: slug });
  const queryString = new URLSearchParams(window.location.search);
  let selectedPlugin = useSelector((state: any) => state.userplugins.value).plugins;
  const [src,setSrc] =useState(`https://ps.w.org/${plugin.slug}/assets/icon-256x256.png`)

  const favorite = false ? favorite_select : favorite_unselect;
  const {t, i18n} = useTranslation()

  useEffect(() => {
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    if (Array.isArray(wishlist)) {
      const isAlreadyInWishlist = wishlist.some((plugins:any) =>
      plugins.id === plugin.id
      );
      if (isAlreadyInWishlist) {
        // setIsPluginInWishlist(true);
        setColor('#F08921');
      }
    }
  },[plugin, view]);

  // const addremove=(data: Iitem, e: any) => {
  //   data = Object.assign({selectedVersion: data.versions[0]?.id}, data)
  //   let pluginData=[...selectedPlugin]
  //   let index=pluginData.findIndex(x => x.id === data.id);
  //   if(index > -1){
  //     pluginData.splice(index, 1);
  //   }else{
  //     pluginData.push(data)
  //   }
  //   try {
  //     const result = syncUserSession({sessonData:JSON.stringify(pluginData)})
  //     result.then((r:any)=>{
  //       switch (e.target.innerHTML) {
  //         case 'Select':
  //           toast.success(`${data.name} added to current queue`);
  //           break;

  //         case 'Remove':
  //           toast.success(`${data.name} removed from current queue`);
  //           break;

  //         default:
  //           break;
  //     }
  //   })
  //   } catch (error) {
  //     toast.error(`${error}`)
  //   }
  // }



  const onAddPlugin = (plugin: any, event: any): void => {
    console.log('plugin', plugin, event, selectPluginData);
    const a = selectPluginData;
    const filterdata = selectPluginData.find((i: any) => {
      return i.id === plugin.id;
    });
    if (filterdata !== undefined) {
      const ab = a.filter((i: any) => {
        return i.id !== plugin.id;
      });
      localStorage.setItem('storequeue', JSON.stringify(ab!));
      setSelectPluginData(ab);
    } else {
      const abc = [...a, plugin];
      localStorage.setItem('storequeue', JSON.stringify(abc!));
      setSelectPluginData(abc);
    }
  };

const addToWishlist = () => {
    if(color === 'black'){
      setColor('F08921')
    }else{
      setColor('F08921')
    }
    console.log("pluginDetails",  plugin);
    const wishlistString = localStorage.getItem('wishlist');
    const wishlist = wishlistString ? JSON.parse(wishlistString) : [];
    const isAlreadyInWishlist = wishlist.some((plugins:any) => plugins.id === plugin.id);
    if (!isAlreadyInWishlist) {
      const newObj = {...plugin, versions:plugin.versions}
      wishlist.push(newObj);
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      toast.success('Plugin Added to wishlist');
      console.log('Added to wishlist:', plugin);

    } else {
      toast.error('Already Added to wishlist');
    }
    }

  const handleImageError = () => {
     setSrc(img)
  }
  const renderRating = () => {
    if (plugin.type === 'private' && plugin.ratings !== undefined) {
      return (
        <div className='tooltip-container'>
          <div className='tooltip'>{t('store-list.plugin.Private')}</div>
        </div>
        //   {/* <div className="price">${plugin.rating}</div> */}
        //   <Rating tooltipDefaultText="Private" showTooltip={true} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" allowHover={false}/>
      );
    } else {
      return (
        <div aria-disabled={true}>
          {/* <div className="price">${plugin.rating}</div> */}
          <Rating
            initialValue={plugin.rating / 20}
            allowFraction={true}
            fillColor='#F28A03'
            size={20}
            className='float-start'
            emptyColor='#888888'
            allowHover={false}
            disableFillHover={true}
            readonly
          />
        </div>
      );
    }
  };
  return (
    <div
    key={plugin.id}
      className={view === 'grid' ? 'col-xxl-3 col-xl-4 col-md-6 col-sm-12 plugin_columns' : 'col-md-12 listmode plugin_columns'}
    >
      <div className='plug_grid'>
        <div className='card-body'>
          <div className={view === 'grid' ? 'plugin_area' : 'plugin_area row g-3 '}>
            <div
              className={
                view === 'grid' ? 'plug_img' : 'plug_img col-sm-12 col-md-6 col-12  col-xl-3 order-md-0 order-xl-0'
              }
            >
              <div className='product_like' onClick={addToWishlist}>
                <FontAwesomeIcon icon={faHeart}  style={{color:color}} size='lg'/>
              </div>
              <img className='store-item-image img-fluid' style={{height:"auto", width:"100%" ,objectPosition:'center'}} src={src} onError={() => handleImageError()} alt=''></img>
              {/* <div className="select_favorite">
          <img className="favorite_select img-fluid" src={favorite} alt=""></img>
        </div> */}
            </div>
            <div
              className={view === 'grid' ? 'list' : 'list col-md-12 col-12 col-sm-12 col-xl-6 order-md-2 order-xl-1'}
            >
              <Link to={`/store/${plugin.slug}`} style={{ textDecoration: 'none' }}>
                <div className='pluginname font20'>{plugin.name ? plugin.name : plugin.slug}</div>
              </Link>
              <div className='author font16'>{t('store-list.plugin.By')}: {plugin.wp_author}</div>
              <div className='up_date font16'>
                <span className='text-white font16'>{t('store-list.plugin.Last-updated')} : </span>{' '}
                {moment(plugin.updated_at).format('DD MMM YYYY') !== '01 Jan 0001'
                  ? moment(plugin.updated_at).format('DD MMM YYYY')
                  : ''}
              </div>
              {view === 'list' ? (
                <div className='list_visible font16'>
                  {/* <div className='plug_desc font16 grey-color'>
                    <span className='text-white'>{t('store-list.plugin.Compatibility')} : </span> Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                    specimen book.
                  </div> */}
                  <div className='tag_cat'>
                    <div className='tag_lst'>
                      <span className='text-white my-1 font16'>{t('store-list.plugin.Tags')} :&nbsp;</span>
                      <div className='tag_list cattag_lst'>
                        <span>{t('store-list.plugin.Plugin')}</span>
                      </div>
                    </div>
                    {/* <div className='categories_lst'>
                      <span className='text-white my-1 font16'>{t('store-list.plugin.Categories')}:&nbsp;</span>
                      <div className='cat_list cattag_lst'>
                        <span>{t('store-list.plugin.Ecommerce')}</span>
                        <span>{t('store-list.plugin.SEO')}</span>
                      </div>
                    </div> */}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div
              className={
                view === 'grid'
                  ? 'pricesection'
                  : 'pricesection col-md-6 col-12 col-sm-12 col-xl-3 order-md-1 order-xl-2 border-sm-width-0 border-md-width-0 border-xl-width-1 justify-content-sm-start justify-content-md-center'
              }
            >
              <div className='row'>
                <div className='col-12 col-md-6'>
                  {renderRating()}
                  {/* <div className="price">${plugin.rating}</div>
            <Rating initialValue={plugin.rating/20} allowFraction={true} fillColor="#F28A03" size={20} className="float-start" emptyColor="#888888" /> */}
                </div>
                <div className='col-12 col-md-6 align-self-end'>
                  <div className='plugin_select_btn'>
                    {/* {active !== null ? (
              <button className={active ? 'orange_btn ms-auto' : 'cblue_btn ms-auto'}
                onClick={(e: any) => {
                  if (active) addremove(plugin, e);
                  else addremove({...plugin,projects:existinProject}, e);
                }}
              >
                {active ? <span>Remove</span> : <span>Select</span>}
              </button>
            ) : null} */}
                    {/* <button className="cblue_btn ms-auto" type="button" disabled={queryString.get("project_id") !== null ? queryString.get("project_id") !== ""?false:true:true} onClick={(e) => onAddPlugin(plugin,e)}>
              {isSelectedActive ? "Remove":"Select"}
            </button> */}
                    <button
                      className='cblue_btn ms-auto'
                      type='button'
                      style={{ zIndex: 9999999999999 }}
                      onClick={e => onAddPlugin(plugin, e)}
                    >
                      {isSelectedActive ?  `${t('store-list.buttons.Remove')}` : `${t('store-list.buttons.Select')}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StorePlugin;
