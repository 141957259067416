// arraySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  arrayData: [
    {
      page:"store",
      trigger:false
    },
    {
      page:"projects",
      trigger:false
    },
    {
      page:"project-details",
      trigger:false
    },
    {
      page:"plugins-details",
      trigger:false
    },
    {
      page:"queue",
      trigger:false
    },
    {
      page:"deploy",
      trigger:false
    }
  ],
};

const arraySlice = createSlice({
  name: 'array',
  initialState,
  reducers: {
    setIntroArrayData: (state, action) => {
      state.arrayData = action.payload;
    },
    updateTrigger: (state, action) => {
      const { page, trigger } = action.payload;
      const pageIndex = state.arrayData.findIndex(item => item.page === page);
      if (pageIndex >= 0) {
        state.arrayData[pageIndex].trigger = trigger;
      }
    },
  },
});

export const { setIntroArrayData,updateTrigger } = arraySlice.actions;
export default arraySlice.reducer;
